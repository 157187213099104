<template>
    <v-container>
        <v-row>
            <v-col class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <v-card>
                    <v-card-text class="profile-card pb-0 mb-5">
                        <v-list-item>
                            <v-list-item-content class="text-center pb-0 ">
                                <v-list-item-title class="mt-5 mb-3">
                                    <v-avatar size="80">
                                        <v-img
                                                :lazy-src="user.gpic"
                                                :src="user.gpic"
                                        ></v-img>
                                    </v-avatar>
                                </v-list-item-title>
                                <v-list-item-title class="title mb-2">{{user.name}}</v-list-item-title>
                                <v-list-item-subtitle class="subtitle text--lighten-5">{{user.position}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                    <v-card-text class="d-flex justify-space-around pt-5 pb-12">
                        <v-chip
                                color="green"
                                outlined
                                dark
                                @click="sendEmail(user.email)"
                        >
                            <v-icon>mdi-email-outline</v-icon>
                        </v-chip>
                        <v-chip
                                color="green"
                                outlined
                                dark
                        >
                            <a :href="'tel:'+user.phone" class="text-decoration-none">
                                <v-icon color="green">mdi-phone</v-icon>
                            </a>
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="mb-5" dense elevation="0">
                    <v-toolbar color="primary" dark  dense elevation="0">
                        <span>
                            <v-icon color="white">mdi-license</v-icon>
                            Færdiggjorte kurser
                        </span>
                    </v-toolbar>
                    <v-skeleton-loader
                            v-if="completedLoad"
                            type="list-item-avatar,list-item"
                    ></v-skeleton-loader>
                    <v-card
                        v-for="course in completedCourses"
                        :key="course.id"
                        elevation="0"
                        flat
                    >
                        <v-sheet
                                flat
                                elevation="0"
                                class="pa-2 pl-4"
                        >
                            <v-icon color="green" class="mr-1">mdi-license</v-icon>
                            <router-link
                                    :to="{name:'Lektion',params:{id:course.id}}"
                                    exact>{{course.name}}
                            </router-link>
                        </v-sheet>
                        <v-divider/>
                    </v-card>
                </v-card>
                <v-card dense elevation="0">
                    <v-toolbar color="primary" dark dense elevation="0">
                        <span>
                            <v-icon color="white">mdi-clock-outline</v-icon>
                            Tidsforbrug
                        </span>
                    </v-toolbar>
                    <v-card-text class="pa-0">
                        <v-data-table
                                :items="timeSpent"
                                :headers="headers"
                                :load="timeSpentLoad"
                                dense
                        ></v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "Profile",
        data() {
            return {
                user: [],
                headers: [
                    {text: 'Note', value: 'note'},
                    {text: 'Tid', value: 'timeSpent', width: 120},
                    {text: 'Dato', value: 'createdDate', width: 180},
                ],
                timeSpent: [],
                completedCourses: [],
                completedLoad: true,
                timeSpentLoad: true,

            }
        },
        methods: {
            getUser() {
                if (this.$router.currentRoute.params.id === this.$store.state.auth.user.id) {
                    this.user = this.$store.state.auth.user
                    console.log(this.$store.state.auth.user)
                }
                api.get('user/' + this.$router.currentRoute.params.id)
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getCourseTime() {
                api.get('course/time/user/' + this.$router.currentRoute.params.id)
                    .then(response => {
                        this.timeSpent = response.data
                        this.timeSpentLoad = false
                    })
            },
            getCompleted() {
                api.get('course/completed/user/' + this.$router.currentRoute.params.id)
                    .then(response => {
                        this.completedCourses = response.data
                        this.completedLoad = false
                    })
            },
            sendEmail(mail) {
                window.open('mailto:' + mail)
            },
        },
        mounted() {
            this.getUser()
            this.getCourseTime()
            this.getCompleted()
        },
        watch: {
            $route(to, from) {
                this.getUser()
                this.getCourseTime()
                this.getCompleted()
            }
        }

    }
</script>

<style scoped>
    .profile-card {
        background: #ffffff;
        background: linear-gradient(337deg, #ffffff 50%, #f1f7f7 50%);
        background: -webkit-linear-gradient(337deg, #ffffff 50%, #f1f7f7 50%);
        background: -moz-linear-gradient(337deg, #ffffff 50%, #f1f7f7 50%);
    }
</style>